import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

import { getUsers } from "../../actions/users";
import Loader from "../Utils/loader.js";

const Userlist = () => {
  const alert = useAlert();
    const dispatch = useDispatch();
    const { loading, users, error } = useSelector(
        (state) => state.allUsersState
      );
    //const user = true;

  useEffect(() => {
    if(error){
      return alert.error(error);
    }
    dispatch(getUsers());
  }, [dispatch,alert,error]);
  
  return (
    <Fragment>
        {loading ? <Loader/> : (
            <div className="container">
            <div className="row mb-2">
              <h2 className="col">Users</h2>
              <div className="col d-flex flex-row-reverse">
                <a className="btn btn-primary" href={"/user/register"}>
                  Create User
                </a>
              </div>
            </div>
            <div>
              {users && users.map((user) => (
                <div class="card mb-2">
                  <div class="card-header">{user.username}</div>
                  <div class="card-body">
                    <h7 class="card-subtitle mb-2 text-muted">
                      {user.firstname}
                    </h7>
                    <p class="card-text">{user.email}</p>
                    <a href={`/user/${user.mat_id}`} class="btn btn-primary">
                      Edit
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
    </Fragment>
  );
};

export default Userlist;
