import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Navbar, Nav, Form, FormControl } from "react-bootstrap";
import "./Nav.css";
import { useParams, Link } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {logout} from "../actions/users";

const Blognav = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, logedUser, error } = useSelector(
    (state) => state.logedUser
  );

  const handleLogout = () => {
    dispatch(logout());
    alert.show("Logged Out Successfully");
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <a className="navbar-brand" href="/home">
          <img
            alt="ES"
            className="img-fluid img-thumbnail"
            src="/images/ES_logo3.png"
            height={50}
            width={150}
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="/home">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Summit
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Images
              </a>
              <div className="bag">
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="/image">
                      Get Image
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Regional
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      International Action
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider"></hr>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Internal
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-disabled="true" href="/ai/foundation">
                Predictions
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-disabled="true" href="/notes">
                Notes
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-disabled="true" href="/blog">
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-disabled="true" href="/users">
                Users
              </a>
            </li>
          </ul>
          <div className="d-flex">
            {logedUser ? 
              (
                <div className="ml-4 dropdown d-inline">
                  <Link
                    to="#!"
                    className="btn dropdown-toggle text-white mr-4"
                    type="button"
                    id="dropDownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <figure className="avatar avatar-nav">
                      <img
                        src={logedUser.avator ? logedUser.avator : "images/default.jpg"}
                        alt={logedUser.username}
                        className="rounded-circle"
                      />
                    </figure>
                    <span className="ml-2">{logedUser.username}</span>
                  </Link>

                  <div className="dropdown-menu" aria-labelledby="dropDownMenuButton">
                    {logedUser && logedUser.role === "user" && (
                      <Link className="dropdown-item text-dark" to="/user/register">
                        Register User
                      </Link>
                    )}
                    <Link className="dropdown-item text-dark" to="/notes">
                      Notes
                    </Link>
                    <Link className="dropdown-item text-dark" to="/blog">
                      Blog
                    </Link>
                    <Link className="dropdown-item text-dark" to="/me">
                      Profile
                    </Link>            
                    <Link
                      className="dropdown-item text-danger"
                      to="/"
                      onClick={handleLogout}
                    >
                      Logout
                    </Link>
                  </div>
                </div>
              )
              :
              !loading &&
              <Link to="/user/login" className="btn ml-4" id="login_btn">
                Login/SignUp
              </Link>
            }
          </div>
          {/* <!--form className="d-flex" role="search">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            ></input>
            <button type="submit" className="btn btn-outline-success">
              Search
            </button>
          </form--!> */}
        </div>
      </div>
    </nav>
  );
};

export default Blognav;
