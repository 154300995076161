import React, { useEffect, useState } from "react";

import { GetBlogs } from "../../actions/blog";

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    //update blogs
    GetBlogs()
      .then((res) => {
        //update notes
        setBlogs(res);
      })
      .catch((error) => {
        setBlogs([
          {
            title: "Error",
            author: "none",
            createdAt: "none",
            summary: `no data available! ${error}`,
            blog_id: 1,
          },
        ]);
      });
  }, []);

  
  return (
    <div className="container">
      <div className="row mb-2">
        <h2 className="col">Articles</h2>
        <div className="col d-flex flex-row-reverse">
          <a className="btn btn-primary" href={"/blog/create"}>
            Create Article
          </a>
        </div>
      </div>
      <div>
        {blogs.map((blog) => (
          <div class="card mb-2">
            <div class="card-header">{blog.title}</div>
            <div class="card-body">
              <h7 class="card-subtitle mb-2 text-muted">
                {blog.author}, {blog.createdAt.substring(0, 10)}
              </h7>
              <p class="card-text">{blog.summary}</p>
              <a href={`/blog/${blog.blog_id}`} class="btn btn-primary">
                Read more...
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogList;
