import axios from "axios";

export const GetBlogs = async () => {
  try {
    const data = await axios.get("/api/blog");
    const jsondataObj = data["data"].data;
    return jsondataObj;
  } catch (error) {
    throw new Error(error);
  }
};

export const GetBlog = async (id) => {
  try {
    const data = await axios.get(`/api/blog/${id}`);
    const jsondataObj = data["data"].data[0];
    return jsondataObj;
  } catch (error) {
    throw new Error(error);
  }
};

export const CreateBlogAction = async (blog) => {
  try {
    const data = await axios.post("/api/blog/create", {
      title: blog.title,
      author: blog.author,
      summary: blog.summary,
      content: blog.content,
    });
    const jsondataObj = data["data"].data[0];
    return jsondataObj;
  } catch (error) {
    throw new Error(error);
  }
};
