import React, { Fragment, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";

import { registerAction } from "../../actions/users";
import "./user.css";

const RegisterUser = () => {
  const params = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();

  const [username, setUsername]=useState("");
  const [mat_uni, setMatUni]=useState("");
  const [lastname, setLastname]=useState("");
  const [firstname, setFirstname]=useState("");
  const [email, setEmail]=useState("");

  const [imageFile, setImageFile] = useState("");
  const [imageFilePreview, setImageFilePreview] = useState("/images/default.jpg");

  const submitHandler = (e) => {
    e.preventDefault();
    const user = {
        username,
        imageFile,
        mat_uni,
        lastname,
        firstname,
        email
    };
    registerAction(user)
        .then((res) => {
            alert.success(`Success: ${res.success}`);
            navigate('/user/login');
        })
        .catch((error) => {
            alert.error(`issue: ${error}`);
        }); 
  };

  const handlecancel = () => {
    setUsername("");
    setMatUni("");
    setLastname("");
    setFirstname("");
    setEmail("");
    setImageFile(null);
    setImageFilePreview("/images/default.jpg");
  };

  const handleFileChange = (e) => {
    //setImageFile(e.target.files[0]);
    if (e.target.name === "image") {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          //setImagePreview(reader.result);
          const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
          setImageFile(base64String);
          setImageFilePreview(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      alert.error('Error with image upload!');
      return;
    }
  };

  return (
    <Fragment>      
        <div className="container">
        <div className="row wrapper my-5">
            <div className="col-10 col-lg-5 mx-auto">
                <form className="shadow-lg px-5 py-3" onSubmit={submitHandler}>
                    <h1 className="mb-3">Register</h1>
                    <div className="form-group">
                        <label htmlFor="lastname_field">Lastname *</label>
                        <input
                            type="name"
                            id="lastname_field"
                            name="lastname_field"
                            className="form-control"
                            value={lastname}
                            onChange={(e) => {
                            setLastname(e.target.value);
                            }}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="firstname_field">Firstname *</label>
                        <input
                            type="name"
                            id="firstname_field"
                            name="firstname_field"
                            className="form-control"
                            value={firstname}
                            onChange={(e) => {
                            setFirstname(e.target.value);
                            }}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email_field">Email *</label>
                        <input
                            type="email"
                            id="email_field"
                            name="email_field"
                            className="form-control"
                            value={email}
                            onChange={(e) => {
                            setEmail(e.target.value);
                            }}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="username_field">Username *</label>
                        <input
                            type="name"
                            id="username_field"
                            name="username_field"
                            className="form-control"
                            value={username}
                            onChange={(e) => {
                            setUsername(e.target.value);
                            }}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="matUni_field">Password</label>
                        <input
                            type="password"
                            id="matUni_field"
                            className="form-control"
                            value={mat_uni}
                            onChange={(e) => {
                                setMatUni(e.target.value);
                            }}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="avatar_upload">Image</label>
                        <div className="d-flex align-items-center">
                            <div>
                                <figure className="avatar mr-3 item-rtl">
                                    <img
                                    src={imageFilePreview}
                                    className="rounded-circle"
                                    alt="Image Preview"
                                    />
                                </figure>
                            </div>
                            <div className="input-group">
                                <input
                                    type="file"
                                    name="image"
                                    className="form-control"
                                    id="input-group"
                                    accept="images/*"
                                    onChange={handleFileChange}
                                />
                                {/* <label className="input-group-text" htmlFor="customFile">
                                    Browse
                                </label> */}
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col d-flex justify-content-center">
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                        </div>
                        <div className="col d-flex justify-content-center">
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={handlecancel}
                        >
                            Cancel
                        </button>
                        </div>
                        <div className="col d-flex justify-content-center">
                        <Link className="btn btn-primary" to="/users">
                            Back
                        </Link>
                        </div>
                    </div>

                </form>
            </div>
        </div>
        </div>
    </Fragment>
  );
};

export default RegisterUser;
