import axios from "axios";
import "./App.css";
import { useAlert } from "react-alert";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";

//maintaining user state
import {getLogedUser} from "./actions/users";
import store from "./reduxMain";

//react components
import Header from "./components/Header";
import Note from "./components/Notes/landing";
import Footer from "./components/Footer";
import Home from "./components/home";
import BlogList from "./components/Blog/listBlogs";
import BlogDetails from "./components/Blog/blogDetails";
import CreateBlog from "./components/Blog/CreateBlog";
import ShowImage from "./components/ImageMgt/showImage";
import ShowSpecificImage from "./components/ImageMgt/showSpecificImage";
import CreateImage from "./components/ImageMgt/createImage";
import PredictFoundationsafety from "./components/Rcc/Foundation";
import Userlist from "./components/Users/AllUsers";
import SpecificUser from "./components/Users/SpecificUser";
import Login from "./components/Users/Login";
import RegisterUser from "./components/Users/RegisterUser";

function App() {
  const alert = useAlert();

  useEffect(() => {
    store.dispatch(getLogedUser());
  },[]);

  return (
    <BrowserRouter>
      <div className="body">
        <Header />
        <Routes>
          <Route path="/notes" Component={Note} exact></Route>
          <Route path="/blog" Component={BlogList} exact></Route>
          <Route path="/blog/:id" Component={BlogDetails} exact></Route>
          <Route path="/blog/create" Component={CreateBlog} exact></Route>
          <Route path="/image" Component={ShowImage} exact></Route>
          <Route path="/image/:id" Component={ShowSpecificImage} exact></Route>
          <Route path="/image/create" Component={CreateImage} exact></Route>
          <Route path="/ai/foundation" Component={PredictFoundationsafety} exact></Route>
          <Route path="/users" Component={Userlist} exact></Route>
          <Route path="/user/login" Component={Login} exact></Route>
          <Route path="/user/register" Component={RegisterUser} exact></Route>
          <Route path="/user/:id" Component={SpecificUser} exact></Route>
          <Route path="*" Component={Home}></Route>
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}
//<Route path="/" component={Blog} exact/>
export default App;
