import { createSlice, configureStore } from '@reduxjs/toolkit';
import {allUserReducer, specificUserReducer, userAuthReducer} from "./reducers/users";
//import userReducer from "./reducers/userSlicers"

const counterSlice = createSlice({
  name: 'counter',
  initialState: {},
  reducers: {
    incremented: state => {
      state.value += 1
    },
    decremented: state => {
      state.value -= 1
    },
  }
})

export const { incremented, decremented, users1 } = counterSlice.actions

const store = configureStore({
  reducer: {
    counter: counterSlice.reducer,
    allUsersState: allUserReducer,
    specificUser: specificUserReducer,
    logedUser: userAuthReducer
}
})

export default store;
