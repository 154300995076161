import {ALL_USER_REQUEST, ALL_USER_SUCCESS, ALL_USER_FAIL, CLEAR_ERRORS,
  SPECIFIC_USER_REQUEST, SPECIFIC_USER_SUCCESS, SPECIFIC_USER_FAIL,
  LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGIN_USER_FAIL,
  LOGED_IN_USER_REQUEST, LOGED_IN_USER_SUCCESS, LOGED_IN_USER_FAIL,
  LOGOUT_USER_SUCCESS, LOGOUT_USER_FAIL
}
 from "../contants/users";

export const allUserReducer = (state = { users: [] }, action) => {
    switch (action.type) {
      case ALL_USER_REQUEST:
        return {
          ...state,
          loading: true,
          users: [],
        };
      case ALL_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          users: action.payload          
        };
      case ALL_USER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
        };
        
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
};

export const specificUserReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case SPECIFIC_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SPECIFIC_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload        
      };
    case SPECIFIC_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};


export const userAuthReducer = (state = { logedUser: {} }, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
    case LOGED_IN_USER_REQUEST:
      return {
        loading: true,
        isAutheticated: false,
      };
    case LOGIN_USER_SUCCESS:
    case LOGED_IN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isAutheticated: true,
        logedUser: action.payload,
      };

    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isAutheticated: false,
        logedUser: null,
        message: action.payload
      };

    case LOGED_IN_USER_FAIL:
      return {
        loading: false,
        isAutheticated: false,
        logedUser: null,
        error: action.payload,
      };
    case LOGIN_USER_FAIL:
      return {
        ...state,
        loading: false,
        isAutheticated: false,
        logedUser: null,
        error: action.payload,
      };
    case LOGOUT_USER_FAIL:
      return{
        ...state, error: action.payload
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};