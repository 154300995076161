import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { PredictFoundation } from "../../actions/Foundation";

const PredictFoundationsafety = () => {
  const navigate = useNavigate();
  const alert = useAlert();

  const [safe, setSafe] = useState("waiting for image...");
  const [imageFile, setImageFile] = useState("");
  const [imageFilePreview, setImageFilePreview] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const newImage1 = {
      FileUrl: imageFile,
    };

    PredictFoundation(newImage1)
      .then((res) => {
        setSafe(res.prediction);
        alert.success(`Success: ${res.success}`);
      })
      .catch((error) => {
        alert.error(`issue: ${error}`);
      });
  };

  const handlecancel = () => {
    setSafe("waiting for image...");
    setImageFilePreview("");
    setImageFile("");
  };

  const handleFileChange = (e) => {
    //setImageFile(e.target.files[0]);
    if (e.target.name === "UploadFile") {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          //setImagePreview(reader.result);
          const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
          setImageFilePreview(reader.result);
          setImageFile(base64String);         
        }
      };

      reader.readAsDataURL(e.target.files[0]);
    } else {
      alert.error('image upload issue!');
      return;
    }
  };

  return (
    <div className="container">
      <h2>Foundation Safety Prediction</h2>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label for="safe">Prediction:</label>
            <input
              type="text"
              name="safe"
              className="form-control"
              id="safe"
              value={safe}
              onChange={(e) => setSafe(e.target.value)}
              readOnly
            ></input>
          </div>
          <div className="form-group">
            <label for="UploadFile">Upload</label>
            <input 
                id="UploadFile"
                name="UploadFile" 
                className="form-control-file" 
                type="file" 
                accept="image/png, image/jpeg"
                onChange={handleFileChange}>
            </input>
          </div>
          <div className="row mt-2">
            <div className="col d-flex justify-content-center">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-danger"
                onClick={handlecancel}
              >
                Cancel
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <a className="btn btn-primary" href={"/"}>
                Back
              </a>
            </div>
          </div>
        </form>
      </div>
    <img                            
        alt="loading..."
        className="img-fluid"
        src={imageFilePreview}
    />
    </div>
  );
};

export default PredictFoundationsafety;
